import React, { useState, useEffect, useRef } from 'react';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import { getViewExtent } from '../../stores/view';
import { useMapMoveEventStore } from '../../stores/mapMoveEvent';
import { isMobile } from 'react-device-detect';
import { Box, CircularProgress } from '@mui/material';
import { useAreaSearchStore } from '../../stores/areaSearch';
import { useLayerStore } from '../../stores/layers';

export default function AreaSearchButton() {
  const stateMapMoveEvent = useMapMoveEventStore((state) => state.eventName);
  const stateLayerSelectorOpened = useLayerStore((state) => state.selectorOpened);
  const lastStateMapMoveEvent = useRef('moveend');
  const areaSearchLayers = useAreaSearchStore((state) => state.areaSearchLayers);
  const [showAreaButton, setShowAreaButton] = useState(false);
  const [areaMessage, setAreaMessage] = useState(false);
  const [areaSearching, setAreaSearching] = useState(false);
  const [areaIcon, setAreaIcon] = useState(<SearchIcon color="primary" />);

  useEffect(() => {
    let message = 'Rechercher dans cette zone';
    if (areaSearchLayers.length == 1) {
      message = areaSearchLayers[0].message;
    }
    setAreaMessage(<> {message}</>)
    if (areaSearchLayers.length == 0 && showAreaButton) {
      setShowAreaButton(false);
    }
  }, [areaSearchLayers, showAreaButton]);

  useEffect(() => {
    if (areaSearching) {
      setAreaIcon(<CircularProgress color="primary" thickness={6} size={24} />);
    } else {
      setAreaIcon(<SearchIcon color="primary" />);
    }
  }, [areaSearching]);

  useEffect(() => {
    if (stateMapMoveEvent && stateMapMoveEvent != lastStateMapMoveEvent.current) {
      lastStateMapMoveEvent.current = stateMapMoveEvent;
      if (stateMapMoveEvent === 'movestart') {
        setShowAreaButton(false);
      } else if (stateMapMoveEvent === 'moveend') {
        setShowAreaButton(areaSearchLayers.length > 0);
      }
    }
  }, [stateMapMoveEvent, areaSearchLayers]);

  const onAreaSearchClick = () => {
    if (areaSearching) {
      return;
    }

    const promises = areaSearchLayers.map((l) => {
      return l.onAreaSearch(getViewExtent());
    });

    setAreaSearching(true);
    Promise.all(promises).finally(() => {
      setAreaSearching(false);
      setShowAreaButton(false);
    });
  };

  if (stateLayerSelectorOpened) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          zIndex: 2,
          '& > :not(style)': { m: '0 8px 12px 8px' },
          position: 'absolute',
          top: !isMobile ? '71px' : '68px',
          left: 0,
          right: 0,
          textAlign: 'center',
          display: !isMobile ? 'inline-flex' : 'flex',
          flexDirection: !isMobile ? 'unset' : 'column',
          alignItems: !isMobile ? 'center' : 'center',
          justifyContent: 'center'
        }}
      >
        {showAreaButton && (
          <Fab
            variant="extended"
            size="small"
            sx={{
              textTransform: 'none',
              bgColor: 'white',
            }}
            onClick={() => {
              onAreaSearchClick();
            }}
          >
            {areaIcon}
            &nbsp;
            {areaMessage}
          </Fab>
        )}
      </Box>
    </>
  );
}

import { imageBasePath } from "../../../utils/image";

/**
 * 
 * @param {Arrray<import("ol/Feature").default<import("ol/geom/Geometry").default>>} features 
 * @returns {String} image src
 */
const siloImageSrc = (features) => {
  if (features.length == 1) {
    return siloSoloImageSrc(features[0]);
  } else {
    return siloClusterImageSrc(features);
  }
}

/**
 * 
 * @param {import("ol/Feature").default<import("ol/geom/Geometry").default>} feature 
 * @returns {String} image src
 */
const siloSoloImageSrc = (feature) => {
  let imageName = 'silo_classique';

  if (feature.get('siloencours') == 'O') {
    imageName = 'silo_en_cours';
  }

  if (feature.get('siprochainsilo') == 'O') {
    imageName = 'prochain_silo';
  }

  if (feature.get('regroupe') == "OUI") {
    imageName += '_regroupe';
  }

  if (feature.get('etatSilo') == 'C') {
    imageName = 'silo_ramasse';
  }

  if (feature.get('vuParTechnicien') == "O") {
    imageName += '_check';
  }

  return imageBasePath() + 'img/silo/'+imageName+'.svg';
}

/**
 * 
 * @param {Arrray<import("ol/Feature").default<import("ol/geom/Geometry").default>>} features 
 * @returns {String} image src
 */
const siloClusterImageSrc = () => {
  return imageBasePath() + 'img/silo/silo_cluster.svg'
}

export { siloImageSrc }
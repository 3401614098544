import React from 'react';
import { nanoid } from 'nanoid';
import { getMaterielAgricole, getMaterielAgricoleByType } from './api';
import { setVectorLayer } from '../../stores/layers';
import VectorSource from 'ol/source/Vector';
import { typeMaterielAgricole } from './enums';
import { style } from './style';
import { addNotification, closeNotification } from '../../stores/notifications';
import { GeoJSON } from 'ol/format';
import { addItems } from '../../stores/menuItems';
import SearchForm from './components/SearchForm';
import { fitToFeatures, highlightFeature } from '../../utils/mapView';
import { imageBasePath } from '../../utils/image';

const id = nanoid();
const props = {
  title: 'Materiel Agricole',
  type: 'Vector',
  visible: false,
  color: '#FCEE8E',
};

let lastApiResponse = null;
let allFeatures = [];
let typeMaterielSelected = []; // default value
let paramsMaterielSelected = null; // default value

const show = async () => {
  const loadNotifId = addNotification({ message: props.title + ' - ' + 'Chargement en cours', variant: 'default' });
  try {
    await loadAndFilter(true);
    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' }); 
  } finally {
    closeNotification(loadNotifId);
  }
};

const loadAndFilter = async (refreshFromApi) => {
  const source = new VectorSource();
  const features = await search(refreshFromApi);
  source.addFeatures(features);
  setVectorLayer({ id, style, source: source, visible: true });
};

const search = async (refreshFromApi) => {
  if (lastApiResponse == null || refreshFromApi) {
    lastApiResponse = await getMaterielAgricole();
  }
  allFeatures = new GeoJSON().readFeatures(lastApiResponse);
  let features = allFeatures;
  let enableCenter = false;

  if (typeMaterielSelected.length > 0 && paramsMaterielSelected == null) {
    features = features.filter((f) => typeMaterielSelected.indexOf(f.get('type')) >= 0); // on filtre par type de materiel selectionné dans la liste avec les items
  } else if (typeMaterielSelected.length == 0 && paramsMaterielSelected != null) {
    enableCenter = true;
    if (paramsMaterielSelected.materiels && paramsMaterielSelected.materiels.length > 0) {
      const codesmateriels = paramsMaterielSelected.materiels.map(v => v.code);
      features = features.filter(
        (f) => codesmateriels.indexOf(f.get('code')) >= 0 
      );
    }
    if (
      paramsMaterielSelected.entreprise != '' &&
      paramsMaterielSelected.entreprise.codeentrep !== ''
    ) {
      // On filtre avec un codeEntreprise depuis le formulaire de recherche
      features = features.filter(
        (f) => f.getProperties().codeentrep === paramsMaterielSelected.entreprise.codeentrep
      );
    }
    if (paramsMaterielSelected.typemateriel != '') {
      features = features.filter(
        (f) => f.getProperties().type === paramsMaterielSelected.typemateriel
      ); // On filtre le type de materiel depuis le formulaire de recherche
      if (paramsMaterielSelected.usine != '') {
        const MaterielByType = await getMaterielAgricoleByType(
          paramsMaterielSelected.typemateriel
        ); // On filtre par une usine de livraison si le type de mat est saissie depuis le formulaire de recherche
        if (MaterielByType.dsmatagri.length > 0) {
          features.map((feature) => {
            // Permet d'ajouter l'usg du matériel en appels le ws WS0063
            const index = MaterielByType.dsmatagri.findIndex(
              (el) => el.codemateriel === feature.getProperties().code
            );
            const { usg } = index !== -1 ? MaterielByType.dsmatagri[index] : '';
            feature.set('usg', usg);
          });
          features = features.filter(
            (f) => f.getProperties().usg === paramsMaterielSelected.usine
          ); // ON filtre par usine depuis le formulaire de recherche
        }
      }
    }
  }

  if (enableCenter && features.length > 0) {
    fitToFeatures(features);
  }
  return features;
};

const hide = () => {
  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
};

// JSX content
const popup = (feature) => (
  <div>
    {feature.code} - {feature.nomentrep} ({feature.codeentrep})
    <div style={{fontSize: '14px'}}>Dernière position: <strong>{feature.datedernpos} à{' '}
    {feature.heuredernpos.replace(/(\d+)h(\d+)'(\d+)"/g, '$1:$2:$3').padStart(8, '0')}</strong></div>
    {feature.grue && feature.grue.codplanteur && (
      <div style={{fontSize: '14px'}}>Planteur: <strong>{feature.grue.nomplanteur} ({feature.grue.codplanteur})</strong></div>
    )}
    {feature.grue && feature.grue.nomcommune && (
      <div style={{fontSize: '14px'}}>Commune: <strong>{feature.grue.nomcommune}</strong></div>
    )}
    {feature.grue && (
      <div style={{fontSize: '14px'}}>Nb cam. / ch.prév.: <strong>{
        feature.grue.nbcamionsc != '' ? parseInt(feature.grue.nbcamionsc) : '0'
      } / {feature.grue.nbcamionsp != '' ? parseInt(feature.grue.nbcamionsp) : '0'}</strong></div>
    )}
  </div>
);

const mapItem = (feature) => {
  feature.set('nanoid', nanoid(), true);
  return feature;
};

const listItem = (feature) => {
  const fProps = feature.getProperties();
  const typeMat = typeMaterielAgricole.find((i) => i.value === fProps.type);

  const imgKey = feature.get('engin') ? 'img_engin_'+feature.get('engin') : 'img';
  const img = imgKey in typeMat ? typeMat[imgKey] : typeMat.img;

  const content = [
    'Code: ' + fProps.code,
    'Code entreprise: ' + fProps.codeentrep,
    'Nom enreprise: ' + fProps.nomentrep,
    'Date de dernière position: ' + fProps.datedernpos,
    fProps.heuredernpos
      ? 'Heure de dernière position: ' +
        fProps.heuredernpos.replace(/(\d+)h(\d+)'(\d+)"/g, '$1:$2:$3').padStart(8, '0')
      : '',
    'Type de materiel agricole: ' + typeMat.label,
    fProps.adrip ? 'Adresse IP: ' + fProps.adrip : '',
  ];

  if (fProps.grue) {
    content.push(`Silo en cours: ${fProps.grue.numerosilo}`);
    content.push(`Planteur: ${fProps.grue.nomplanteur} (${fProps.grue.codplanteur})`);
    content.push(`Commune: ${fProps.grue.nomcommune}`);
    content.push(
      `Nb cam. / ch.prév.: ${
        fProps.grue.nbcamionsc != '' ? parseInt(fProps.grue.nbcamionsc) : '0'
      } / ${fProps.grue.nbcamionsp != '' ? parseInt(fProps.grue.nbcamionsp) : '0'}`
    );
  }

  return {
    itemId: fProps.nanoid,
    titleHeader: fProps.code,
    subHeader: fProps.nomentrep + ' ' + fProps.codeentrep,
    avatar: <img src={imageBasePath() + img} />,
    content: content,
    actions: [
      {
        icon: 'CenterFocusStrong',
        title: 'Centrer',
        handleClick: () => {
          highlightFeature(id, feature);
        },
      },
    ],
  };
};

const menuItems = () => {
  const actions = [
    {
      key: nanoid(),
      text: 'Voir tous les matériels agricoles',
      icon: 'Search',
      selected: paramsMaterielSelected == null && typeMaterielSelected.length == 0,
      handleClick: async () => {
        typeMaterielSelected = [];
        paramsMaterielSelected = null;
        await show();
      },
    },
    {
      key: nanoid(),
      text: 'Recherche avancée',
      icon: 'Search',
      selected: paramsMaterielSelected != null,
      handleClick: () =>
        addItems({
          component: SearchForm,
          props: {
            materiels: allFeatures.sort((a, b) => {
              return (a.get('code') || '').localeCompare(b.get('code') || '');
            }).map(f => {
              return f.getProperties();
            }),
            onSubmit: async (data) => {
              paramsMaterielSelected = data;
              typeMaterielSelected = [];
              await loadAndFilter(false);
              return Promise.resolve();
            },
          },
          children: null,
        }),
    },
    {
      key: 'tous_les_filres',
      text: <span>Filtres par type
        {typeMaterielSelected.length > 0 ? <strong> ({typeMaterielSelected.length} actif
        {typeMaterielSelected.length > 1 ? 's' : ''})</strong> : ''}</span>,
      open: false,
      childs: typeMaterielAgricole.map((typeMat) => {
        return {
          key: nanoid(),
          text: `Voir les ${typeMat.label.toLowerCase()}s`,
          icon: typeMaterielSelected.indexOf(typeMat.value) >= 0 ? 'CheckBox' : 'CheckBoxOutlineBlank',
          selected: typeMaterielSelected.indexOf(typeMat.value) >= 0,
          handleClick: async () => {
            paramsMaterielSelected = null;
            if (typeMaterielSelected.indexOf(typeMat.value) >= 0) {
              typeMaterielSelected.splice(typeMaterielSelected.indexOf(typeMat.value), 1);
            } else {
              typeMaterielSelected.push(typeMat.value)
            }
            await loadAndFilter(false);
          },
        };
      }),
    },
  ];

  return actions;
};

export { id, props, show, hide, popup, mapItem, listItem, menuItems };

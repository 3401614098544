import React from 'react';
import { nanoid } from 'nanoid';
import {
  getCircuitChampApi,
  sendCircuitChampApi,
  deleteCircuitChampApi,
  updateCircuitChampApi,
} from './api';
import { GeoJSON } from 'ol/format';
import { setVectorLayer } from '../../stores/layers';
import { getViewZoom, getViewExtent } from '../../stores/view';
import { addNotification, closeNotification } from '../../stores/notifications';
import { useInteractionsStore, addInteraction, removeInteraction } from '../../stores/interactions';
import VectorSource from 'ol/source/Vector';
import Collection from 'ol/Collection';
import { addItems } from '../../stores/menuItems';
import DrawForm from './components/DrawForm';
import EditForm from './components/EditForm';
import { style } from './style';
import {
  createGeometry as createGeometryEntryCircuitChamps,
  updateGeometry as updateGeometryEntryCircuitChamps,
  updateGeometryProperties as updateGeometryPropertiesEntryCircuitChamps,
} from './bindings/geometry-entry';
import { parseISO, format } from 'date-fns';
import { typecircuit } from './enums';
import { highlightFeature } from '../../utils/mapView';
import { Avatar } from '@mui/material';
import { circuitIconSrc } from './icon';
import { disableAreaSearch, enableAreaSearch } from '../../stores/areaSearch';

const id = nanoid();
const props = {
  title: 'Circuit champ',
  type: 'Vector',
  visible: false,
  color: '#BDA558',
};

let typeCircuitSelected = '';

const show = async (extent) => {
  enableAreaSearch(id, 'Rechercher les circuits dans cette zone', show);

  if (getViewZoom() > 12) {
    const notifId = addNotification({ message: props.title + ' - ' + 'Chargement en cours', variant: 'default', persist: true });
    try {
      const response = await getCircuitChampApi({ extent, type: typeCircuitSelected });
      const { type, features } = response;
      const source = new VectorSource({
        features: new GeoJSON().readFeatures({ type, features: features }),
      });
      addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
      setVectorLayer({ id, style, source, visible: true });
    } finally {
      closeNotification(notifId);
    }
  } else {
    addNotification({
      message: props.title + ' - ' + 'Zoomer pour afficher des données',
      variant: 'warning',
    });
    setVectorLayer({ id, style, source: new VectorSource(), visible: true });
  }
};

const hide = () => {
  disableAreaSearch(id);

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
};

const popup = () => `${props.title}`;

const create = async (feature, data) => {
  const createGeometryEntry = await createGeometryEntryCircuitChamps({
    feature,
    data,
  });
  const response = await sendCircuitChampApi(createGeometryEntry);
  return response;
};

const edit = async (feature, data) => {
  if (data) {
    const updateGeometryPropertiesCircuitChamps = await updateGeometryPropertiesEntryCircuitChamps({
      feature,
      data,
    });
    const response = await updateCircuitChampApi(updateGeometryPropertiesCircuitChamps);
    return response;
  } else {
    const updateGeomeCircuitChamps = await updateGeometryEntryCircuitChamps({
      feature,
    });
    const response = await updateCircuitChampApi(updateGeomeCircuitChamps);
    return response;
  }
};

const menuItems = () => {
  const menuItems = [{
    key: nanoid(),
    text: 'Voir tous les circuits',
    icon: 'Search',
    selected: typeCircuitSelected == '',
    handleClick: () => {
      typeCircuitSelected = '';
      show(getViewExtent());
    },
  }];

  typecircuit.forEach(t => {
    menuItems.push({
      key: nanoid(),
      text: 'Voir les circuits '+t.label.toLowerCase(),
      icon: 'Search',
      selected: typeCircuitSelected == t.value,
      handleClick: () => {
        typeCircuitSelected = t.value;
        show(getViewExtent());
      },
    });
  });

  menuItems.push({
    key: nanoid(),
    text: 'Dessiner un circuit champ',
    icon: 'LocationSearching',
    handleClick: () =>
      addItems({
        component: DrawForm,
        props: {
          onSubmit: (data) => {
            addNotification({
              message:
                'Dessinez le circuit champ sur la carte puis double-cliquez pour finaliser la création',
              variant: 'default',
            });
            return new Promise((resolve, reject) => {
              const intId = nanoid();
              addInteraction({
                id: intId,
                type: 'Draw',
                layerId: id,
                geomType: 'LineString',
                handleDrawend: async (e) => {
                  removeInteraction({ id: intId });
                  const feature = e.feature;
                  try {
                    const response = await create(feature, data);
                    if (response.gid) {
                      addNotification({
                        message: props.title + ' - ' + ' créé',
                        variant: 'success',
                      });
                      show(getViewExtent());
                      resolve();
                    } else {
                      addNotification({
                        message: props.title + ' - ' + 'NON créé',
                        variant: 'error',
                      });
                      reject('something wrong');
                    }
                  } catch (err) {
                    show(getViewExtent());
                    reject(err);
                  }
                },
              });
            });
          },
        },
        children: null,
      }),
  });

  return menuItems; 
}; // icons list: https://mui.com/components/material-icons/

const mapItem = (feature) => {
  feature.set('nanoid', nanoid(), true);
  return feature;
};

const listItem = (feature) => {
  const fProps = feature.getProperties();
  const intModifyId = nanoid();

  const actions = [
    {
      icon: 'CenterFocusStrong',
      title: 'Centrer',
      handleClick: () => {
        highlightFeature(id, feature);
      },
    },
    {
      icon: 'EditLocation',
      title: 'Modifier le circuit',
      handleClick: () => {
        const interaction = useInteractionsStore
          .getState()
          .interactions.find((item) => item.id === intModifyId);
        if (!interaction) {
          const notifActions = [
            {
              title: 'Confirmer',
              handleClick: async () => {
                removeInteraction({ id: intModifyId });
                try {
                  await edit(feature);
                  addNotification({
                    message: 'Circuit champ modifié',
                    variant: 'success',
                  });
                  show(getViewExtent());
                } catch (error) {
                  console.log(error);
                  addNotification({
                    message: 'Impossible de modifier le circuit champ',
                    variant: 'error',
                  });
                }
              },
            },
            {
              title: 'Annuler',
              handleClick: () => {
                removeInteraction({ id: intModifyId });
                show(getViewExtent());
              },
            },
          ];

          addNotification({
            message: props.title + ' - ' + 'Modification du circuit champ',
            variant: 'info',
            persist: true,
            actions: notifActions,
          });

          addInteraction({
            id: intModifyId,
            type: 'Modify',
            features: new Collection([feature]),
            handleModifyend: () => {},
          });
        }
      },
    },
    {
      icon: 'Edit',
      title: 'Modifier les infos',
      handleClick: async () => {
        addItems({
          component: EditForm,
          props: {
            onSubmit: async (data) => {
              try {
                await edit(feature, data);
                addNotification({
                  message: 'Infos du circuit champ modifiées',
                  variant: 'success',
                });
                show(getViewExtent());
                return Promise.resolve();
              } catch (err) {
                addNotification({
                  message: 'Impossible de modifier les infos du circuit champ',
                  variant: 'error',
                });
                return Promise.reject(err);
              }
            },
            circuitChampProps: fProps,
          },
          children: null,
        });
      },
    },
    {
      icon: 'Delete',
      title: 'Supprimer',
      handleClick: async () => {
        try {
          await deleteCircuitChampApi(feature.getId());
          addNotification({
            message: 'Circuit champ supprimé',
            variant: 'success',
          });
          show(getViewExtent());
        } catch (error) {
          addNotification({
            message: 'Impossible de supprimer le circuit champ',
            variant: 'error',
          });
        }
      },
    },
  ];

  return {
    itemId: fProps.nanoid,
    titleHeader: props.title,
    subHeader: '',
    avatar: (
      <Avatar src={circuitIconSrc(feature)} />
    ),
    content: [
      fProps.code_grue ? 'Grue: ' + fProps.code_grue : '',
      fProps.code_silo ? 'Silo: ' + fProps.code_silo : '',
      fProps.type_circuit
        ? 'Type de circuit: ' + typecircuit.find((i) => i.value === fProps.type_circuit).label
        : '',
      fProps.end_display
        ? "Fin d'affichage: " + format(parseISO(fProps.end_display), 'dd/MM/yyyy')
        : '',
    ],
    actions,
  };
};

export { id, props, show, hide, popup, mapItem, listItem, menuItems };

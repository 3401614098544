import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useView, useLayers, useInteractions, useNotifications } from './index';
import Map from 'ol/Map';
import Cluster from 'ol/source/Cluster';
import Overlay from 'ol/Overlay';
import { defaults as defaultInteractions } from 'ol/interaction';
import { MousePosition, ScaleLine, defaults as defaultControls } from 'ol/control';
import { boundingExtent, createEmpty, equals, getCenter } from 'ol/extent';
import {
  addedRasterLayer,
  removedRasterLayer,
  addedVectorLayer,
  removedVectorLayer,
} from '../stores/layers';
import { addMapMoveEvent } from '../stores/mapMoveEvent';
import { useItemsStore, highlightItem } from '../stores/menuItems';
import { getViewZoom, useViewStore } from '../stores/view';
import { addedInteraction, removedInteraction } from '../stores/interactions';
import * as features from '../features';
import can from '../utils/duckTyping';
import debounce from '../utils/debounce';
import MarkerPopup from '../components/MarkerPopup';
import { isMobile } from 'react-device-detect';
import { useMenuToolsStore } from '../stores/menuTools';
import * as tools from '../features/tools';
import useWindowSize from './useWindowSize';
import { createStringXY } from 'ol/coordinate';
import { setMap, useMapStore } from '../stores/map';
import VectorTileLayer from 'ol/layer/VectorTile';
import { showLocation } from '../features/geolocation';

const useMap = () => {
  useNotifications();
  const map = useMapStore((state) => state.map);
  const popupRef = useRef();
  const popupRoot = useRef();
  const [view] = useView();
  const { vectorLayers, rasterLayers } = useLayers();
  const [interactions] = useInteractions();

  const setViewCenter = useViewStore((state) => state.setViewCenter);
  const setViewZoom = useViewStore((state) => state.setViewZoom);
  const setViewExtent = useViewStore((state) => state.setViewExtent);
  const windowSize = useWindowSize();

  const stateHighlightedItem = useItemsStore((state) => state.highlightItem);

  // init map
  useEffect(() => {
    const map = new Map({
      interactions: defaultInteractions().extend([]),
      controls: isMobile
        ? defaultControls({ zoom: false }).extend([])
        : defaultControls({ zoom: true }).extend([
            new ScaleLine({
              units: 'metric',
            }),
            new MousePosition({
              coordinateFormat: createStringXY(0),
              projection: 'EPSG:3857',
            }),
          ]),
    });

    map.addOverlay(
      new Overlay({
        id: 'overlay-popup',
        element: popupRef.current,
        autoPan: true,
      })
    );

    setMap(map);
  }, []);

  // setup map events
  useEffect(() => {
    if (map) {
      map.once('postcompose', () => {
        const stateTools = useMenuToolsStore.getState().tools;
        const toolsKeys = Object.keys(tools);
        toolsKeys.map((key) => {
          stateTools.map(async (tool) => {
            if (key === 'prefTech' && tool.id.includes(tools[key].id)) {
              await tools[key].init();
            }
          });
        });

        if (isMobile) {
          showLocation(false);
        }
      });

      map.on('movestart', () => {
        addMapMoveEvent('movestart');
      });

      map.on(
        'moveend',
        debounce(() => {
          setViewCenter(view.getCenter());
          setViewZoom(view.getZoom());
          setViewExtent(view.calculateExtent(map.getSize()));
        }, 500)
      );

      map.on('moveend', () => {
        addMapMoveEvent('moveend');
      });

      map.on('singleclick', (e) => {
        const overlayPopup = map.getOverlayById('overlay-popup');
        // Options pour ne pas chercher la feature sélectionnée dans les layers de selection
        const pixelOptions = {
          layerFilter: (layer) => {
            const selectionLayer = vectorLayers.filter(
              (l) => l.get('selection') && l.get('id') === layer.get('id')
            );
            return selectionLayer.length == 0;
          },
        };
        const pixel = map.forEachFeatureAtPixel(
          e.pixel,
          (feature, layer) => ({
            feature,
            layer,
          }),
          pixelOptions
        );

        if (pixel) {
          let shouldHighlight = false;

          if (pixel.feature.get('features') && pixel.feature.get('features').length > 1) {
            // cluster
            const pFeatures = pixel.feature.get('features');
            const extent = boundingExtent(pFeatures.map((r) => r.getGeometry().getCoordinates()));
            const emptyExtent = createEmpty();

            if (!equals(emptyExtent, extent)) {
              if (getViewZoom() >= 20) {
                shouldHighlight = true;
              } else {
                view.fit(extent, {
                  size: [windowSize.width, windowSize.height],
                  padding: [100, 100, 100, 100],
                });
              }
            }
          } else {
            shouldHighlight = true;
          }

          if (shouldHighlight) {
            if (pixel.feature.get('features')) {
              pixel.feature = pixel.feature.get('features')[0];
            }

            if (!pixel.layer) {
              return;
            }

            // reset all previous highlighted features
            if (!(pixel.layer instanceof VectorTileLayer)) {
              const source =
                pixel.layer.getSource() instanceof Cluster
                  ? pixel.layer.getSource().getSource()
                  : pixel.layer.getSource();
              source.getFeatures().map((f) => f.unset('highlight'));

              pixel.feature.set('highlight', true);
            }

            highlightItem({
              key: pixel.feature.get('nanoid'),
              layer: pixel.layer,
              feature: pixel.feature,
              scrollToCard: true,
            });
          }
        } else {
          // reset all previous highlighted features
          const stateHighlightItem = useItemsStore.getState().highlightItem;
          if (
            stateHighlightItem &&
            stateHighlightItem.feature &&
            stateHighlightItem.feature != null
          ) {
            stateHighlightItem.feature.unset('highlight');
          }
          highlightItem({});
          overlayPopup.setPosition(undefined);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, view, setViewExtent, setViewCenter, setViewZoom]);

  // show popup of a new highlighted feature
  useEffect(() => {
    if (
      !map ||
      !stateHighlightedItem ||
      !stateHighlightedItem.feature ||
      !stateHighlightedItem.layer
    ) {
      return;
    }

    const overlayPopup = map.getOverlayById('overlay-popup');
    const keys = Object.keys(features);

    keys.map((key) => {
      if (
        features[key].id === stateHighlightedItem.layer.get('id') &&
        can(features[key], 'popup')
      ) {
        setTimeout(() => {
          overlayPopup.setPosition(
            getCenter(stateHighlightedItem.feature.getGeometry().getExtent())
          );
        }, 100); // Delay to let the popup width update, so the autoPan works properly
        if (popupRef.current) {
          // as we are not in React scope
          // we use ReactDOM.render() in order to show popup
          if (!popupRoot.current) {
            popupRoot.current = createRoot(popupRef.current);
          }
          popupRoot.current.render(
            <MarkerPopup
              onClick={() => {
                overlayPopup.setPosition(undefined);
                if (stateHighlightedItem.feature) {
                  stateHighlightedItem.feature.unset('highlight');
                }
                highlightItem({});
              }}
            >
              {features[key].popup(stateHighlightedItem.feature.getProperties())}
            </MarkerPopup>
          );
        }
      }
    });
  }, [map, stateHighlightedItem]);

  // dispatch event on map after map move
  // useEffect(() => {
  //   if (map && stateMapEvent && stateMapEvent.type !== '') {
  //     // use 'once' here in order in to avoid event loop
  //     map.once('moveend', () => {
  //       if (!stateMapEvent.pixel) {
  //         stateMapEvent.pixel = map.getPixelFromCoordinate(stateMapEvent.coordinate);
  //       }
  //       map.dispatchEvent(stateMapEvent);
  //     });
  //   }
  // }, [map, stateMapEvent]);

  // add or modify the map view
  useEffect(() => {
    if (map) {
      map.setView(view);
    }
  }, [map, view]);

  // add a raster layer
  useEffect(() => {
    if (map && rasterLayers) {
      const layersToAdd = rasterLayers.filter((l) => l.get('state') === 'ADD');
      // .filter((l) => l.get('visible') !== false);
      layersToAdd.map((l) => map.addLayer(l));
      if (layersToAdd.length > 0) {
        layersToAdd.map((l) => {
          addedRasterLayer({ id: l.get('id') });
          l.set('state', 'ADDED');
        });
      }
    }
  }, [map, rasterLayers]);

  // remove a raster layer
  useEffect(() => {
    if (map && rasterLayers) {
      const layersToRemove = rasterLayers.filter((l) => l.get('state') === 'REMOVE');
      layersToRemove.map((layer) => {
        const rasterLayer = map
          .getLayers()
          .getArray()
          .find((l) => l.get('id') === layer.get('id'));
        return map.removeLayer(rasterLayer);
      });
      if (layersToRemove.length > 0) {
        layersToRemove.map((l) => removedRasterLayer({ id: l.get('id') }));
      }
    }
  }, [map, rasterLayers]);

  // update a raster layer
  useEffect(() => {
    if (map && rasterLayers) {
      const layersToUpdate = rasterLayers.filter((l) => l.get('state') === 'SET');
      layersToUpdate.map((layer) => {
        const rasterLayer = map
          .getLayers()
          .getArray()
          .find((l) => l.get('id') === layer.get('id'));

        if (rasterLayer !== undefined) {
          rasterLayer.setProperties({ ...layer.getProperties() });
          return rasterLayer;
        }
      });
      if (layersToUpdate.length > 0) {
        layersToUpdate.map((l) => {
          addedRasterLayer({ id: l.get('id') });
          l.set('state', 'ADDED');
        });
      }
    }
  }, [map, rasterLayers]);

  // add a vector layer
  useEffect(() => {
    if (map && vectorLayers) {
      const layersToAdd = vectorLayers.filter((l) => l.get('state') === 'ADD');
      layersToAdd.map((l) => map.addLayer(l));
      if (layersToAdd.length > 0) {
        layersToAdd.map((l) => {
          addedVectorLayer({ id: l.get('id') });
          l.set('state', 'ADDED');
        });
      }
    }
  }, [map, vectorLayers]);

  // remove a vector layer
  useEffect(() => {
    if (map && vectorLayers) {
      const layersToRemove = vectorLayers.filter((l) => l.get('state') === 'REMOVE');
      layersToRemove.map((layer) => {
        const vectorLayer = map
          .getLayers()
          .getArray()
          .find((l) => l.get('id') === layer.get('id'));
        return map.removeLayer(vectorLayer);
      });
      if (layersToRemove.length > 0) {
        const overlayPopup = map.getOverlayById('overlay-popup');
        overlayPopup.setPosition(undefined);
        layersToRemove.map((l) => removedVectorLayer({ id: l.get('id') }));
      }
    }
  }, [map, vectorLayers]);

  // update a vector layer
  useEffect(() => {
    if (map && vectorLayers) {
      const layersToUpdate = vectorLayers.filter((l) => l.get('state') === 'SET');
      layersToUpdate.map((layer) => {
        const vectorLayer = map
          .getLayers()
          .getArray()
          .find((l) => l.get('id') === layer.get('id'));

        if (vectorLayer !== undefined) {
          return vectorLayer;
        }
      });
      if (layersToUpdate.length > 0) {
        const overlayPopup = map.getOverlayById('overlay-popup');
        overlayPopup.setPosition(undefined);
        layersToUpdate.map((l) => {
          addedVectorLayer({ id: l.get('id') });
          l.set('state', 'ADDED');
        });
      }
    }
  }, [map, vectorLayers]);

  // add a interaction
  useEffect(() => {
    if (map && interactions) {
      const interactionsToAdd = interactions.filter((i) => i.get('state') === 'ADD');
      interactionsToAdd.map((i) => map.addInteraction(i));
      if (interactionsToAdd.length > 0) {
        interactionsToAdd.map((i) => addedInteraction({ id: i.get('id') }));
      }
    }
  }, [map, interactions]);

  // remove a interaction
  useEffect(() => {
    if (map && interactions) {
      const interactionsToRemove = interactions.filter((i) => i.get('state') === 'REMOVE');
      interactionsToRemove.map((interaction) => {
        const i = map
          .getInteractions()
          .getArray()
          .find((i) => i.get('id') === interaction.get('id'));
        return map.removeInteraction(i);
      });
      if (interactionsToRemove.length > 0) {
        interactionsToRemove.map((i) => removedInteraction({ id: i.get('id') }));
      }
    }
  }, [map, interactions]);

  // update a interaction
  useEffect(() => {
    if (map && interactions) {
      const interactionsToUpdate = interactions.filter((i) => i.get('state') === 'SET');
      interactionsToUpdate.map((interaction) =>
        map
          .getInteractions()
          .getArray()
          .find((i) => i.get('id') === interaction.get('id'))
          .setProperties({ ...interaction.getProperties() })
      );
      if (interactionsToUpdate.length > 0) {
        interactionsToUpdate.map((i) => addedInteraction({ id: i.get('id') }));
      }
    }
  }, [map, interactions]);

  return [popupRef];
};

export default useMap;
